<template>
	<!-- 引导从默认浏览器打开该页面进行下载 -->
	<div v-if="showGuide" class="guide" @click="closeGuideApp">
		<img
			src="../assets/images/open-browser.png"
			alt="guide"
		/>
	</div>
</template>

<script>
export default {
	name: "GuideApp",
	components: {},
	props: {
		showGuide: {
			type: Boolean,
			value: false
		}
	},
	methods: {
		closeGuideApp(){
			this.$emit('update:showGuide', false);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.guide {
	@include row(flex-end, flex-start);
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 1004;
	overflow: hidden;
	> img {
		width: 100%;
		margin-top: px2rem(-30);
		margin-right: px2rem(-30);
	}
}
</style>