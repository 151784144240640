<template>
	<div class="loading-box" v-show="showLoading">
		<div class="loading-main">
			<div class="lottie">
				<lottie
					:options="lottieOptions"
					@animalCreated="handleAnimation"
					class="lottie"
				/>
			</div>
			<span>用力加载中…</span>
		</div>
	</div>
</template>

<script>
import Lottie from "vue-lottie";
import LoadingLottie from "../../public/loading-lottie.json";
export default {
	name: "Loading",
	components: {
		Lottie,
	},
	props: {
		showLoading: {
			type: Boolean,
			value: false,
		}
	},
	data() {
		return {
			lottieOptions: {
				animationData: LoadingLottie,
				loop: true,
				autoplay: true,
			},
			lottie: "",
		};
	},
	methods: {
		handleAnimation(lottie) {
			this.lottie = lottie;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.loading-box {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1003;
	> .loading-main {
		@include column(flex-start, center);
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: px2rem(192);
		height: px2rem(192);
		border-radius: px2rem(24);
		background-color: rgba(0, 0, 0, 0.7);
		> .lottie {
			width: px2rem(60);
			height: px2rem(70);
			margin-top: px2rem(32);
		}
		> span {
			font-size: px2rem(24);
			font-family: PingFangSC-Regular, PingFang SC;
			color: #fff;
			line-height: px2rem(40);
			margin-top: px2rem(16);
		}
	}
}
</style>
