<template>
	<div class="toast">
		{{ title }}
	</div>
</template>

<script>
export default {
	name: "Toast",
	components: {},
	props: {
		title: {
			type: String,
			value: "",
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.toast {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: px2rem(200);
	padding: px2rem(20);
	color: #fff;
	font-size: px2rem(30);
	text-align: center;
	background-color: rgba(0, 0, 0, .8);
	border-radius: px2rem(12);
	z-index: 999;
}
</style>
